import Vue from 'vue'
import Vuex from 'vuex'

import shopify from './modules/shopify'
import windows from './modules/windows'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    shopify, windows
  }
})
