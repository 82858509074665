const state = {
  showShop: false,
  showStory: false,
  showGallery: false,
  showContact: false,
  showMenu: false,
  showInfo: false,
  currentWindow: '',
  musicPlaying: false,
};

const actions = {
  windowClicked(context, windowTitle) {
    const titleCapitalized = windowTitle.charAt(0).toUpperCase() + windowTitle.slice(1)
    if (state['show' + titleCapitalized] == true) {
      context.commit('setCurrentWindow', '')
      context.dispatch('toggle', {windowTitle: windowTitle, value: false})
      history.back()
    }
    else {
      context.commit('setCurrentWindow', windowTitle)
      context.dispatch('toggle', {windowTitle: windowTitle, value: true})
    }
  },

toggle (context, {windowTitle, value}) {
  switch(windowTitle) {
    case 'shop':
      context.commit('setShowShop', value)
      break;
    case 'menu':
      context.commit('setShowMenu', value)
      break;
    case 'story':
      context.commit('setShowStory', value)
      break;
    case 'gallery':
      context.commit('setShowGallery', value)
      break;
    case 'info':
      context.commit('setShowInfo', value)
      break;
    case 'contact':
      context.commit('setShowContact', value)
      break;
  }
}
}

const mutations = {
  setShowShop (state, value) {
    state.showShop = value
  },
  setShowStory (state, value) {
    state.showStory = value
  },
  setShowGallery (state, value) {
    state.showGallery = value
  },
  setShowContact (state, value) {
    state.showContact = value
  },
  setShowMenu (state, value) {
    state.showMenu = value
  },
  setShowInfo (state, value) {
    state.showInfo = value
  },
  setCurrentWindow (state, value) {
    state.currentWindow = value
  },
  setMusicPlaying (state, value) {
    state.musicPlaying = value
  },
}

const getters = {
}


export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true
};