<template>
  <div id="contact">
    <div id="contact-container">
      <form id="contact-form"
        data-netlify="true"
        name="Contact"
        method="POST"
        @submit.prevent="handleSubmit"
      >
        <router-link class="exit" :to="'/'"><u>EXIT X</u></router-link>
        <div class="form-item">
          <label for="name">NAME: </label>
          <input required type="name" name="name" v-model="form.name">
        </div>
        <div class="form-item">
          <label for="email">EMAIL: </label>
          <input required v-model="form.email" name="email" type="email">
        </div>
        <div class="form-item">
          <label for="message">MESSAGE: </label>
          <textarea required v-model="form.message" name="message" id="" rows="4"></textarea>
        </div>
        <button type="submit">SEND</button>
        <div class="status" v-show="!!status == true">
          <div class="success" v-show="status == 'SUCCESS'">GREAT TALK, {{name.toUpperCase().split(' ')[0]}}. WE'LL GET BACK ASAP</div>
          <div class="sending" v-show="status == 'SENDING'">SENDING >>>></div>
          <div class="failed" v-show="status == 'FAILED'">WHOOPS, THAT AIN'T WORKIN</div>
        </div>
      </form>
    </div>
  </div>
</template>


<script>
import { mapActions } from 'vuex';

export default {
  name: 'Contact',
  data() {
    return{
      form: {
        name: '',
        email: '',
        message: '',
      },
      status: '',
      name: ''
    }
  },
  components: {
  },
  methods: {
    ...mapActions('windows', ['windowClicked']),
    encode (data) {
      return Object.keys(data)
        .map(
          key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
        )
        .join("&");
    },
    async handleSubmit () {
      this.status = "SENDING"
      const axiosConfig = {
        header: { "Content-Type": "application/x-www-form-urlencoded" }
      };
      try {
      await this.$axios.post(
        "/",
        this.encode({
          "form-name": "Contact",
          ...this.form
        }),
        axiosConfig
      );
      } catch {
        this.status = "FAILED"
      }
      this.status = "SUCCESS"
      this.name = this.form.name

      this.form ={
        name: '',
        email: '',
        message: '',
      }
    }
  },
  mounted() {
  },
}
</script>

<style lang="css" scoped>
  /* THIS IS NESSICARY FOR SOME REASON IDK */
  .fake-u-out {
    display: none
  }

  #contact-form {
    font-size: 1rem;
    /* padding: 1rem; */
    border: 1px solid black;
    max-width: 700px;
    min-width: 500px;
    margin: 4rem;
    background-color: white;
  }
  .form-item {
    display: flex;
    flex-direction: column;
    padding: 0rem 1rem;
  }
  .form-item label{
    margin-bottom: .5rem;
  }
  #contact input{
    font-size: 1rem;
    padding: 1rem .75rem;
    border: none;
    border: 1px solid black;
    margin-bottom: 1rem;
  }
  .form-item textarea{
    font-size: 1rem;
    padding: 1rem .75rem;
    border: none;
    border: 1px solid black;
    margin-bottom: 1rem;
    font-family: 'Work Sans', sans-serif;
    flex: 1
  }
  button {
    font-size: 1rem;
    background: white;
    border: none;
    cursor: pointer;
    margin: 2rem auto;
    display: block;
  }

  .exit {
    background: white;
    text-align: right;
    text-decoration: none;
    display: block;
    border-bottom: 1px solid black;
    padding: 1rem;
    margin-bottom: 1rem;
  }

  #contact-container {
    min-height: 106vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("../assets/img/gallery/db_sbfl-65.jpg");
  }

  .status > *{
    padding: 2rem;
    text-align: center;
    border: 1px black solid;
  }
  .status > .success{
    padding: 2rem;
    text-align: center;
    border-color: #00b344;
    color: #00b344;
  }
  .status > .failed{
    padding: 2rem;
    text-align: center;
    border-color: red;
    color: red;
  }

@media only screen and (max-width: 700px) {
  #contact-form {
    font-size: 1rem;
    /* padding: 1rem; */
    border: 1px solid black;
    width: 100vw;
    min-width: unset;
    max-width: unset;
    margin: 1rem;
    background-color: white;
  }
}
</style>