<template>
  <div id="added-to-cart" v-show="itemAdded" @click="close()">
    <img :src="imgs[index]" alt="" srcset="" >
  </div>
</template>

<script>

export default {
  name: 'Cart',
  data() {
    return {
      itemAdded: false,
      index: 0,
      imgs: [
        require('../assets/svg/BAGGED.svg'),
      ]
    }
  },
  props: {
  },
  methods: {
    open() {
      this.itemAdded = true
      window.setTimeout(() => {
        this.close()
      }, 3000)
    },
    close() {
        this.itemAdded = false
        this.index = (this.index  + 1) % this.imgs.length
    }
  }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

#added-to-cart {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 950;

  height: 100vh;
  width: 100vw;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
}
#added-to-cart img{
  height: 50vh;
  width: 50vw;
}

@media only screen and (max-width: 700px) {
  #added-to-cart {
    background: white
  }
  #added-to-cart img{
    height: 90vh;
    width: 100vw;
    object-fit: contain;
  }
}
</style>