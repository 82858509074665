<template>
  <div id="home" @click="startVibes">
    <router-link class="full-screen-text-holder" to="/">
      <div class="full-screen-text scroll">
        <h1>IDE TRACK  SIDE TRACK  SIDE TRACK  SIDE TRACK  SIDE TRACK  SIDE TRAC</h1>
        <h1>K  SIDE TRACK  SIDE TRACK  SIDE TRACK  SIDE TRACK  SIDE TRACK  SIDE TRACK  S</h1>
      </div>
    </router-link>
    <router-link class="full-screen-text-holder" :to="enums.SHOP != selectedWindow ? '/shop' : '/'">
      <div class="full-screen-text">
         <h2 :style="{marginLeft: getRandomPosition('shop')}">SHOP  SHOP  SHOP  SHOP  SHOP  SHOP  SHOP  SHOP  SHOP  SHOP  SHOP  SHOP  SHOP  SHOP  SHOP  </h2>
      </div>
    </router-link>
    <shop v-show="enums.SHOP == selectedWindow"/>
    <router-link class="full-screen-text-holder" :to="enums.GALLERY != selectedWindow ? '/gallery' : '/'">
      <div class="full-screen-text">
         <h2 :style="{marginLeft: getRandomPosition('gallery')}">GALLERY  GALLERY  GALLERY  GALLERY  GALLERY  GALLERY  GALLERY  GALLERY  GALLERY  GALLERY  GALLERY  GALLERY</h2>
      </div>
    </router-link>
    <gallery v-if="enums.GALLERY == selectedWindow"/>
    <router-link class="full-screen-text-holder" :to="enums.MENU != selectedWindow ? '/menu' : '/'">
      <div class="full-screen-text">
         <h2 :style="{marginLeft: getRandomPosition('cafe')}">CAFE MENU  CAFE MENU  CAFE MENU  CAFE MENU  CAFE MENU  CAFE MENU  CAFE MENU  CAFE MENU  CAFE MENU  CAFE MENU</h2>
      </div>
    </router-link>
    <cafe-menu v-show="enums.MENU == selectedWindow"/>
    <router-link class="full-screen-text-holder" :to="enums.STORY != selectedWindow ? '/story' : '/'">
      <div class="full-screen-text">
         <h2 :style="{marginLeft: getRandomPosition('story')}">STORY  STORY  STORY  STORY  STORY  STORY  STORY  STORY  STORY  STORY  STORY  STORY  STORY  STORY  STORY</h2>
      </div>
    </router-link>
    <story v-show="enums.STORY == selectedWindow"/>
    <router-link class="full-screen-text-holder" :to="enums.INFO != selectedWindow ? '/info' : '/'">
      <div class="full-screen-text">
         <h2 :style="{marginLeft: getRandomPosition('info')}">INFORMATION  INFORMATION  INFORMATION  INFORMATION  INFORMATION  INFORMATION  INFORMATION  INFORMATION</h2>
      </div>
    </router-link>
    <info v-show="enums.INFO == selectedWindow"/>
    <router-link class="full-screen-text-holder" :to="enums.CONTACT != selectedWindow ? '/contact' : '/'">
      <div class="full-screen-text">
         <h2 :style="{marginLeft: getRandomPosition('contact')}">CONTACT  CONTACT  CONTACT  CONTACT  CONTACT  CONTACT  CONTACT  CONTACT  CONTACT  CONTACT  CONTACT  CONTACT</h2>
      </div>
    </router-link>
    <contact v-show="enums.CONTACT == selectedWindow"/>
    <a class="full-screen-text-holder" href="https://www.instagram.com/sidetrackcoffee/" target="_blank">
      <div class="full-screen-text">
         <h2 :style="{marginLeft: getRandomPosition('insta')}">INSTAGRAM  INSTAGRAM  INSTAGRAM  INSTAGRAM  INSTAGRAM  INSTAGRAM  INSTAGRAM  INSTAGRAM  INSTAGRAM  INSTAGRAM</h2>
      </div>
    </a>
    <a class="full-screen-text-holder" href="https://open.spotify.com/user/gnz63bn18ouuzt9moevi73vyd?si=kudTaEi0SGOQjvb0P2SwGA" target="_blank">
      <div class="full-screen-text">
         <h2 :style="{marginLeft: getRandomPosition('radio')}">RADIO  RADIO  RADIO  RADIO  RADIO  RADIO  RADIO  RADIO  RADIO  RADIO  RADIO  RADIO  RADIO  RADIO  RADIO  RADIO  RADIO  RADIO  </h2>
      </div>
    </a>
    <a class="full-screen-text-holder">
      <vibes ref="vibes"/>
      <div class="full-screen-text">
         <h2 @click="$refs.vibes.pause()" :style="{marginLeft: getRandomPosition('sound off')}" v-show="musicPlaying">SOUND OFF  SOUND OFF  SOUND OFF  SOUND OFF  SOUND OFF  SOUND OFF  SOUND OFF  SOUND OFF  SOUND OFF  SOUND OFF  SOUND OFF  SOUND OFF  SOUND OFF  </h2>
         <h2 @click="$refs.vibes.play()" :style="{marginLeft: getRandomPosition('sound on')}" v-show="!musicPlaying">SOUND ON  SOUND ON  SOUND ON  SOUND ON  SOUND ON  SOUND ON  SOUND ON  SOUND ON  SOUND ON  SOUND ON  SOUND ON  SOUND ON  SOUND ON  </h2>
      </div>
    </a>
    <div class="full-screen-text" style="float: left">
      <h2 id="date" style="float: left">DATE: {{date}}</h2>
      <h2 id="time" style="float: right">TIME: {{time}}</h2>
    </div>
  </div>
</template>

<script>

import seedrandom from 'seedrandom';

// @ is an alias to /src
import shop from './Shop'
import story from './Story'
import gallery from './Gallery'
import contact from './Contact'
import cafeMenu from './Menu'
import info from './Info'
import vibes from '@/components/Vibes'

import { mapState } from 'vuex';

export default {
  name: 'Home',
  data() {
    return{
      now: new Date(),
      randHash: {},
      enums: {
        SHOP: 'shop',
        GALLERY: 'gallery',
        MENU: 'menu',
        STORY: 'story',
        INFO: 'info',
        CONTACT: 'contact',
      },
      selectedWindow: ''
    }
  },
  components: {
    shop, story, gallery, contact, cafeMenu, info, vibes
  },
  watch:{
    $route (to){
      this.setWindow(to)
    }
  },
  methods: {
    setWindow(window) {
      this.selectedWindow = window.path.split("/")[1]
    },
    startVibes() {
      this.$refs.vibes.startAudio()
    },
    getRandomPosition(title) {
      if (!!this.randHash[title] == true) {
        return this.randHash[title]
      }
      let UID =  localStorage.getItem('UID')
      if (!!UID == false) {
        localStorage.setItem('UID', Math.random().toFixed(3))
        UID =  localStorage.getItem('UID')
      }

      const randomNum = seedrandom(title + UID)() * -10
      this.randHash[title] = randomNum + 'rem'
      return randomNum + 'rem'
    },
  },
  mounted() {
    this.setWindow(this.$route)

    setInterval(() => this.now = new Date(), 1000);
  },
  computed: {
    date() {
      let today = new Date();
      let dd = today.getDate();
      let mm = today.getMonth()+1;
      let yyyy = today.getFullYear();
      if(dd<10)
      {
          dd='0'+dd;
      }

      if(mm<10)
      {
          mm='0'+mm;
      }
      today = mm+'.'+dd+'.'+yyyy;
      return today
    },
    time() {
      const now = this.now

      const hour = now.getHours();
      let min = now.getMinutes();
      let sec = now.getSeconds();

      min = min < 10 ? '0' + min: min
      sec = sec < 10 ? '0' + sec: sec

      return hour + ':' + min + ':' + sec
    },
    ...mapState('windows', ['musicPlaying'])
  }
}
</script>


<style scoped>

@keyframes scroll {
  0%   {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(-50%);
  }
}

#home {
  min-height: 100vh;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.scroll {
  animation-name: scroll;
  animation-duration: 30s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.full-screen-text-holder {
  text-decoration: none;
}
.full-screen-text {
  white-space: nowrap;
  display: block;
  position: relative;
  display: inline-block;
  word-spacing: 1rem;
}
.full-screen-text h2{
  transition: 1s margin-left ease;
}

@media only screen and (max-width: 700px) {
  h1, h2 {
    margin: 1rem 0rem;
  }
}

</style>
