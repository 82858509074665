<template>
  <div class="coffee-card">
    <div class="product-card">
      <a class="exit" href="javascript:history.back()"><u>EXIT X</u></a>
      <div class="title">
        <h3>{{selectedProduct.title}}</h3>
      </div>
      <div class="price">
        ${{selectedProduct.variants[selectedVariant].price.amount}}
      </div>
      <div class="description-toggle"
        @click="shrinkDescription = !shrinkDescription"
      >
        {{'Description ' + (shrinkDescription ? '+' : '-')}}
      </div>
      <!-- <div class="description">
        {{selectedProduct.description}}
      </div> -->
      <div class="description"
        v-html="selectedProduct.descriptionHtml"
        :class="{'shrink': shrinkDescription}"
      ></div>
      <div class="variants" v-if="selectedProduct.variants.length > 1">
        <div v-for="(variant, index) in selectedProduct.variants" :key="variant.id" class="variant"
          @click="selectedVariant = variant.available ? index: selectedVariant"
          :class="{'unavailable': !variant.available, 'selected': selectedVariant == index}"
        >
          {{ variant.title }}
        </div>
      </div>
      <div class="number-adjuster" v-if="selectedProduct.availableForSale">
          <div class="minus noselect"
            :class="{'disabled': quantity <= 1}"
            @click="quantity = quantity <= 1 ? quantity : quantity - 1"
          >-</div>
          <!-- <div class="number">
            {{quantity}}
          </div> -->
          <input type="number" min="1" class="number" v-model="quantity">
          <div class="add noselect"
            @click="quantity++"
          >+</div>
        </div>
      <div class="add-to-bag noselect" v-if="selectedProduct.availableForSale"
        @click="addToCart({variantID: selectedProduct.variants[selectedVariant].id, quantity: quantity})"
      >
        ADD TO BAG
      </div>
      <div class="sold-out noselect" v-show="!selectedProduct.availableForSale">
        SOLD OUT
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'ProductCard',
  data() {
    return {
      selectedVariant: 0,
      quantity: 1,
      shrinkDescription: true,
    }
  },
  props: {
    selectedProduct: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  methods: {
    ...mapActions('shopify', ['addToCart'])
  },
  watch: {
    quantity(n) {
      n = Number(n)
      if (n < 0) {
        n = 0
      }
      this.quantity = n
    }
  }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

@media only screen and (max-width: 700px) {
  .description-toggle {
    display: block !important;
  }
}

.product-card {
  display: grid;
  grid-template-columns: 5fr 3fr 2fr;
  border: .5px black solid;
  box-sizing: border-box;
  padding: 0px;
  transition: all 2s 0s ease;
  background: #ffffff70;
  width: 100%;
}
.product-card:hover {
  background: white;
  transition: all .7s .3s ease;
}
.product-card > *{
  border: .5px black solid;
  margin: 0px;
  box-sizing: border-box;
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: flex-start; */
  padding: .75rem 1rem;
  font-size: 1rem;
  font-weight: 500;
}
.product-card .title {
  padding: 0px;
  grid-column: 1/3;
  grid-row: 2/3;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.product-card h3 {
  font-size: 1.25rem;
  margin: 0px;
  font-weight: 500;
  padding: 1rem;
}
.product-card .price {
  grid-column: 3/4;
  grid-row: 2/3;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  font-size: 1.2rem;
}
.description-toggle {
  grid-column: 1/4;
  text-align: left;
  display: none;
}
.product-card .description {
  grid-column: 1/4;
  min-height: 15rem;
  max-height: 18rem;
  overflow: auto;
}
.product-card .description >>> *{
  margin: .2rem 0px .4rem 0px;
}
.product-card .description >>> h6{
  margin: 1.5rem 0px .75rem 0px;
}
.product-card .description >>> h6:first-child{
  margin: 0rem 0px .75rem 0px;
}
.label {
  text-transform: uppercase;
  margin-bottom: .2rem;
  font-size: .75rem;
}
.label .toggle{
  float: right;
  cursor: pointer;
  /* font-size: 1rem; */
}
.richText {
  grid-column: 1/3;
}
.exit {
  float: right;
  background: #ffffff70;
  grid-column: 1/4;
  grid-row: 1/2;
  text-align: right;
  text-decoration: none;
}
.variants {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: stretch;
  grid-column: 1/4;
  padding: 0px;
  overflow-x: auto;
}
.variant {
  border: .5px black solid;
  border-bottom-width: .25px;
  border-top-width: 0px;
  flex: 1;
  padding: 1rem;
  text-align: center;
  cursor: pointer;
}
.variant.selected {
  background: #44caff;
}
.variant.unavailable {
  cursor: default;
  color: black;
  background-image: url(../assets/svg/x.svg);
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  background-size: 100% 100%;
  background-color: white;
}
.number-adjuster {
  grid-column: 1/2;
  text-align: center;
  padding: 1rem;
  font-size: 1rem;
  display: flex;
  justify-content: space-between;
  padding: 0px;
}
.number-adjuster > *{
  border: .5px black solid;
  border-bottom-width: 0px;
  border-top-width: 0px;
  padding: 1rem;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  cursor: pointer;
}
.number-adjuster .disabled{
  color: #adadad;
}
.number-adjuster .number,
.number-adjuster .number::selection,
.number-adjuster .number::-moz-selection {
  border-radius: 0px;
  text-align: center;
  outline: none;
  width: 3rem;
  font-size: 1.1rem;
}
.number-adjuster .number {
  border-radius: 0px;
  text-align: center;
  outline: none;
  width: 3rem;
  font-size: 1.1rem;
  cursor: text;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.add-to-bag {
  grid-column: 2/4;
  cursor: pointer;
  text-align: center;
  padding: 1rem;
  font-size: 1rem;
}
.sold-out {
  grid-column: 1/4;
  text-align: center;
  color: red
}

@media only screen and (max-width: 700px) {
  .product-card .description.shrink {
    height: 0px;
    min-height: unset;
    max-height: unset;
    padding: 0px;
    border: 0px black solid;
  }
  .variant.unavailable {
    background-attachment: scroll;
  }
}

</style>