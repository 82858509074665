import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import PageNotFound from '../views/PageNotFound'
// import Admin from '../views/Admin.vue'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/shop/:productType?/:productID?',
    name: 'Shop',
    component: Home
  },
  {
    path: '/story',
    name: 'Story',
    component: Home
  },
  {
    path: '/menu/:productName?',
    name: 'Menu',
    component: Home
  },
  {
    path: '/menu/direct',
    name: 'DirectMenu',
    component: Home
  },
  {
    path: '/info',
    name: 'Info',
    component: Home
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Home
  },
  {
    path: '/gallery',
    name: 'Gallery',
    component: Home
  },
  // {
  //   path: '/admin',
  //   name: 'admin',
  //   component: Admin
  // },
  { path: "*", name: 'PageNotFound', component: PageNotFound }
]

const router = new VueRouter({
  routes
})

export default router
