<template>
  <div id="info" v-if="infoSections.length > 0">

    <div class="info-section" v-for="section in infoSections" :key="section.title">
      <div class="section-title">{{ section.title }}:</div>
      <ul class="section-items">
        <li v-for="item in section.items" :key="item">
          {{ item }}
        </li>
      </ul>
    </div>

    <div class="info-section">
      <div class="section-title">ADDRESS:</div>
      <ul class="section-items">
        <a @click="mapsSelector()">
          817 South Railroad Avenue<br>
          Opelika AL<br>
          36801<br>
          United States of America<br>
        </a>
      </ul>
    </div>

    <div class="info-section">
      <div class="section-title">PHONE:</div>
      <ul class="section-items">
        <a href="tel:334-707-8906">1 (334) 707-8906</a>
      </ul>
    </div>

    <div class="info-section">
      <div class="section-title">EMAIL:</div>
      <ul class="section-items">
        <li><a href="mailto:david@sidetrackcoffee.co">david@sidetrackcoffee.co</a></li>
        <li><a href="mailto:austin@sidetrackcoffee.co">austin@sidetrackcoffee.co</a></li>
      </ul>
    </div>
  </div>
</template>


<script>

export default {
  name: 'Info',
  data() {
    return{
      infoSections: []
    }
  },
  components: {
  },
  methods: {
    mapsSelector() {
      if /* if we're on iOS, open in Apple Maps */
        ((navigator.platform.indexOf("iPhone") != -1) ||
        (navigator.platform.indexOf("iPad") != -1) ||
        (navigator.platform.indexOf("iPod") != -1))
      {
        window.open("maps://maps.google.com/maps?daddr=32.647570,-85.379592&amp;ll=");
      }
      else /* else use Google */ {
        window.open("https://maps.google.com/maps?daddr=32.647570,-85.379592&amp;ll=");
      }
    },
    async getInfo() {
      let files = await require.context('../../public/collections/info/', false, /\.json$/);
      let infoPlural = files.keys().map(key => {
        let res = files(key);
        return res;
      });
      const info = infoPlural[0]
      this.infoSections = info.sections
    }
  },
  mounted() {
    this.getInfo()
  },
}
</script>


<style lang="css" scoped>
  #info {
    font-size: 2.5rem;
    padding: 1rem 0px;
    margin-bottom: 2rem;
  }
  .info-section {
    border-bottom: 2px black solid;
    float: left;
    width: 98%;
    padding: 2rem 1%;
  }
  .section-title {
    float: left
  }
  .section-items {
    float: right;
  }
  ul {
    padding: 0px;
    margin: 0px;
    text-align: right;
  }

  li {
    list-style: none
  }

@media only screen and (max-width: 700px) {
  #info {
    font-size: 1rem;
    padding: 1rem 0px;
    margin-bottom: 2rem;
  }

  .info-section {
    border-bottom: 1px black solid;
  }
}
</style>