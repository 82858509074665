<template>
  <div id="cafe-menu" v-if="menuSections.length > 0">
    <popup
      ref="popup"
      :title="selectedItem.title"
      :html="selectedItem.description"
      :img="selectedItem.image"
      :clickAway="true"
      @closed="deselectMenuItem"
    />
    <div class="menu-section" v-for="section in menuSections" :key="section.title">
      <div class="section-title">{{ section.title }}:</div>
      <ul class="section-items">
        <li v-for="item in section.items" :key="item.title">
          <router-link  @click="setPosition" v-if="!!item.description"
            :to="'/menu/' + item.title.replace(' ', '_')"> <div  @click="setPosition">{{item.title}}</div></router-link>
          <div v-else>{{ item.title }}</div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

import Popup from "../components/Popup"

const EMPTY_ITEM = {
  "title": "",
  "image": "",
  "description": ""
}

export default {
  name: 'Menu',
  data() {
    return {
      menuSections: [],
      selectedItem: EMPTY_ITEM,
      selectedItemFound: false,
    }
  },
  props: {
  },
  components: {
    Popup
  },
  methods: {
    async setup() {
      await this.getMenu()
      const params = this.$route.params
      const productName = params['productName']
      if (!!productName == true) {
        this.selectMenuItem(productName)
      }
    },
    async getMenu() {
      let files = await require.context('../../public/collections/menu_v2/', false, /\.json$/);
      let menus = files.keys().map(key => {
        let res = files(key);
        return res;
      });
      const menu = menus[0]
      this.menuSections = menu.sections
    },
    setPosition(event) {
      if (window.innerWidth > 700) {
        const x = event.clientX
        const y = event.clientY

        let right = window.innerWidth - x - 250
        let top = y -20

        if (window.innerWidth > 700) {
          right = Math.max(right, 30)
          right = Math.min(window.innerWidth - 400, right)

          top = Math.min(top, window.innerHeight * .5 - 30)
        }

        this.$refs.popup.setPosition(right, top)
      }
      else {
        const top = window.outerHeight * .25
        this.$refs.popup.setPosition(0, top)
      }
    },
    deselectMenuItem() {
      this.$router.push("/menu")
    },
    selectMenuItem(itemTitle) {
      this.selectedItemFound = false
      const title = itemTitle.replace('_', ' ')
      if (this.menuSections.length > 0) {
        for (let index = 0; index < this.menuSections.length; index++) {
          const section = this.menuSections[index];
          if (!!section.items == true) {
            for (let j = 0; j < section.items.length; j++) {
              const item = section.items[j];
              if (item.title == title) {
                this.selectedItem = item
                this.$refs.popup.open()
                return
              }
            }
          }
        }
      }
      this.selectedItemFound = true
    }
  },
  watch:{
    $route (to){
      const params = to.params
      const productName = params['productName']
      if (!!productName == true) {
        this.selectMenuItem(productName)
      }
    }
  },
  computed: {
  },
  mounted() {
    this.setup()
  }
}
</script>

<style lang="css" scoped>
  #cafe-menu {
    padding: 1rem 0px;
    margin-bottom: 2rem;
  }
  .menu-section {
    font-size: 2.5rem;
    border-bottom: 2px black solid;
    float: left;
    width: 98%;
    padding: 2rem 1%;
  }
  ul {
    padding: 0px;
    margin: 0px
  }

  li {
    list-style:none;
    text-align: right;
    padding: .5rem 0px;
  }
  li a{
    text-decoration: underline;
    display: block;
  }

@media only screen and (max-width: 700px) {
  #cafe-menu {
    padding: 1rem 0px;
    margin-bottom: 2rem;
  }

  .menu-section {
    font-size: 1rem;
    border-bottom: 1px black solid;
  }
}
</style>