<template>
  <div id="cart" v-show="showCart">
    <div class="header">
      <div class="title">BAG</div>
      <div class="exit"
        @click="setShowCart(false)"
      ><u>EXIT X</u></div>
    </div>
    <div class="items">
      <div v-if="!!checkout.lineItems == true" v-show="checkout.lineItems.length < 1" class="empty">BAG EMPTY</div>
      <div v-for="item in checkout.lineItems" :key="item.id"
        class="item"
      >
        <img :src="item.variant.image.src" alt="">
        <div class="info">
          <div class="title">{{item.title}}</div>
          <div class="price">PRICE: ${{item.variant.price.amount}}</div>
          <div class="quantity">QTY: {{item.quantity}}</div>
          <div class="price" v-show="item.variant.title != 'Default Title'">SIZE: {{item.variant.title}}</div>
        </div>
        <div class="remove"
          @click="removeFromCart(item.id)"
        >X</div>
      </div>
    </div>
    <div class="footer">
      <a class="checkout"
        :href="checkout.webUrl"
      >CHECKOUT</a>
      <div class="subtotal">SUBTOTAL: ${{checkout.paymentDue.amount}}</div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  name: 'Cart',
  data() {
    return {
    }
  },
  props: {
  },
  methods: {
    ...mapActions('shopify', ['addToCart', 'removeFromCart']),
    ...mapMutations('shopify', ['setShowCart'])
  },
  computed: {
    ...mapState('shopify', ['showCart', 'checkout'])
  },
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  #cart {
    position: fixed;
    bottom: 1rem;
    right: 2rem;
    z-index: 700;

    min-width: 400px;
    min-height: 300px;

    overflow: auto;

    background: white;
    border: 1px black solid;

    display: flex;
    flex-direction: column;
  }
  .header {
    display: flex;
    flex-direction: row;
    border-bottom: 1px black solid;
    padding: .5rem 1rem;
    font-size: 1rem;
  }
  .header .title {
    flex: 1;
  }
  .header .exit {
    cursor: pointer;
  }
  .items {
    overflow: auto;
    max-height: 48vh;
    padding: .5rem 0px;
    flex: 1;
  }
  .empty {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 10rem;
  }
  .item {
    font-size: 1rem;
    padding: .5rem 1rem;
    display: flex;
  }
  .item img{
    object-fit: cover;
    width: 6rem;
    height: 8rem;
  }
  .item .info {
    padding-left: 1rem;
    flex: 1;
  }
  .info .title{
    font-weight: 500;
  }
  .item .remove {
    cursor: pointer;
  }
  .footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    border-top: 1px black solid;
    font-size: 1rem;
  }
  .footer > * {
    padding: .5rem 1rem;
  }
  .footer .checkout {
    cursor: pointer;
    border-right: 1px black solid;
  }
  .footer .subtotal {
    flex: 1;
    text-align: right;
  }


@media only screen and (max-width: 700px) {

  #cart {
    position: fixed;
    bottom: 0px;
    right: 0px;
    left: 0px;

    /* width: 100vw; */

    min-width: unset;
    min-height: unset;

    overflow: auto;

    background: white;
    border: 1px black solid;

    display: flex;
    flex-direction: column;
  }

  .footer > * {
    padding: 1.25rem 1.5rem;
  }
}
</style>