<template>
  <div id="shop" ref="shop">
    <div v-if="!!!selectedProductType" id="product-types">
      <div v-for="pType in productTypes" :key="pType">
        <router-link :to="'/shop/' + pType">
          <h2>{{ pType.toUpperCase() }}</h2>
        </router-link>
      </div>
    </div>
    <div v-show="!!!selectedProductID && selectedProductType" id="products">
      <div class="type-header">
        <router-link class="back" :to="'/shop'"><u>&#60; BACK</u></router-link>
        <h2 v-if="!!!selectedProductID && !!selectedProductType">{{selectedProductType.toUpperCase()}}</h2>
      </div>
      <div class="product-list">
      <router-link v-for="product in productsByType" :key="product.id" class="product-list-card"
        :to="'/shop/' + selectedProductType + '/' + product.id">
          <img :src="product.images[0].src" alt="">
          <h4>{{product.title}}</h4>
          <h4 class="sold-out" v-show="!product.availableForSale">SOLD OUT</h4>
      </router-link>
      </div>
    </div>
    <div v-if="!!selectedProductID" id="product">
      <div class="carousel">
        <div class="images" v-if="!!selectedProduct.images">
          <div v-for="(image, index) in selectedProduct.images" :key='image.id'>
            <img :src="image.src" alt="" height="100%" width="auto">
            <div>{{index + 1}}</div>
          </div>
        </div>
      </div>
      <div class="product-card-holder" ref="product"
        :class="{'fix-product': fixBox,
                  'box-bottom': fixBottom
                }"
      >
        <product-card
          :selectedProduct="selectedProduct"
        />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

  import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';

import ProductCard from '@/components/ProductCard'

export default {
  name: 'Shop',
  data() {
    return {
      selectedVariant: 0,
      fixBox: false,
      fixBottom: false,
      settings: {
        dots: true,
        arrows: true,
        infinite: false,
        centerMode: false,
        centerPadding: "0px",
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        draggable: false,
        speed: 2000,
        cssEase: "linear",
        swipeToSlide: false,
        focusOnSelect: true,
      }
    }
  },
  methods: {
    ...mapActions('shopify', ['updateSelectedProduct']),
    ...mapMutations('shopify', ['setSelectedProductType', 'setSelectedProductID']),
    checkURL () {
      const params = this.$route.params
      const productType = params.productType
      const productID = params.productID
      this.setSelectedProductType(productType)
      if (!!productID == true) {
        this.setSelectedProductID(productID)
        this.updateSelectedProduct()
        this.selectedVariant = 0;
      }
      else {
        this.setSelectedProductID('')
      }
    },
    onScroll() {
      const TOP_OFFEST = 50
      const BOTTOM_OFFSET = 150

      if (!!this.$refs.product == false)
        return

      const shopTop = this.$refs.shop.getBoundingClientRect().top
      const shopBottom = this.$refs.shop.getBoundingClientRect().bottom
      const productBottom = this.$refs.product.getBoundingClientRect().bottom
      const productTop = this.$refs.product.getBoundingClientRect().top
      const bottomDifference = shopBottom - productBottom

      this.fixBox = shopTop <= (0 - TOP_OFFEST)
      this.fixBottom = bottomDifference <= (1 + BOTTOM_OFFSET) && productTop < TOP_OFFEST + 1

      this.previousScroll = window.scrollY

    }
  },
  mounted() {
    this.checkURL()
    window.addEventListener("scroll", this.onScroll)
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll)
  },
  computed: {
    ...mapState('shopify', ['productTypes', 'selectedProductType', 'selectedProductID', 'selectedProduct']),
    ...mapGetters('shopify', ['productsByType'])
  },
  watch: {
    $route() {
      this.checkURL()
    }
  },
  components: {
    ProductCard,
  }
}
</script>


<style scoped>

#product-types {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  min-height: 20rem;
}

#products {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 3.5rem;
}
.type-header {
  display: flex;
  width: 100%;
}
.type-header h2 {
  flex: 1;
  text-align: center;
}
.back {
  /* border: 1px black solid; */
  text-decoration: none;
  position: absolute;
  /* width: 3.5rem; */
  display: inline-block;
  text-align: center;
  margin-left: 5rem;
}
.product-list {
  padding: 3.5rem 0px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  justify-items: center;
  width: 100%;
  gap: 5rem;
}
.product-list-card {
  width: 15rem;
  display: flex;
  flex-direction: column;
  grid-template-rows: auto;
  border: black 1px solid;
  text-decoration: none;
  font-weight: 400;
}
.product-list-card img{
  height: 18rem;
  grid-column: 1/2;
  grid-row: 1/2;
  width: 100%;
  object-fit: cover;
}
.product-list-card h4{
  font-size: 1rem;
  padding: .5rem;
  margin: 0px;
  border-top: black 1px solid;
  font-weight: 500;
  text-align: center;
}
.product-list-card .sold-out{
  color: red;
  text-decoration: none !important;
  text-align: center;
  font-weight: 400;
}
#product {
  min-height: 40rem;
  position: relative;
  padding-bottom: 200px;
}
.carousel {
  margin: 0rem 0px;
  position: relative;
  top: 100px;
  left: 50%;
  width: 100px;
  /* margin-bottom: 200px; */
}
.images {
  display: flex;
  width: 35vw;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.product-card-holder {
  position: absolute;
  left: 12vw;
  top: 100px;
  min-width: 500px;
  width: 40vw;
  z-index: 700;
}
.product-card-holder.fix-product {
  position: fixed;
  top: 50px;
  /* bottom: 100px; */
}
.product-card-holder.box-bottom {
  position: absolute;
  top: auto;
  bottom: 150px;
}


@media only screen and (max-width: 700px) {
  .back {
    /* border: 1px black solid; */
    text-decoration: none;
    /* width: 2.5rem; */
    font-size: 1.5rem;
    display: inline-block;
    text-align: center;
    margin-left: 0px;
    margin-right: 5rem;
  }
  .type-header h2 {
    text-align: right;
  }
  #products {
    font-size: 1.5rem;
  }
  .carousel {
    margin: 0px !important;
    width: 100vw;
    left: 0px;
  }
  .carousel .images {
    margin: 0px !important;
    width: 100vw;
  }
  .carousel .images img{
    width: 100vw;
    height: unset;
  }
  .product-card-holder {
    position: fixed !important;
    left: 0px !important;
    right: 0px !important;
    bottom: 0px !important;
    top: unset !important;
    width: 100vw !important;
    min-width: unset !important;
    background-color: white;
  }
  .product-list {
    width: unset;
  }
}

.carousel >>> .slick-list {
  overflow: visible;
}
img {
  height: 23rem
};

#why {
  color: purple
}
</style>
