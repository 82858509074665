<template>
  <div id="gallery">
    <div class="target left-target noselect"
      @click="move(false)"
      @mouseover="startFollow('LEFT')"
      @mouseleave="stopFollow('LEFT')"
    ></div>
    <div :class="{ 'scroll-left': left, 'scroll-right': right}" class="scroll" draggable="true">
      <img src="../assets/svg/point.svg" :class="{'right': right}" alt="" srcset="" v-show="left || right" class="pointer"
        :style="{'left': mousePos[0] + 'px', 'top': mousePos[1] + 'px'}"
      >
      <div class="carousel">
        <div v-for="image in images" :key='image.image'>
          <img :src="image.image" alt="" height="100%" width="auto">
          <div class="number">{{image.number}} <span class="for-phone-only inline"> / {{images.length}}</span></div>
        </div>
        <!-- <div v-for="(image, index) in images" :key='image + "2"'>
          <img :src="image" alt="" height="100%" width="auto">
          <div>{{index + 1}}</div>
        </div> -->
      </div>
    </div>
    <div class="target right-target noselect"
      @click="move(true)"
      @mouseover="startFollow('RIGHT')"
      @mouseleave="stopFollow('RIGHT')"></div>
  </div>
</template>

<script>
// @ is an alias to /src

  import { mapActions, mapMutations } from 'vuex';

  // import VueSlickCarousel from 'vue-slick-carousel'
  // import 'vue-slick-carousel/dist/vue-slick-carousel.css'
  // // optional style for arrows & dots
  // import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'


export default {
  name: 'Gallery',
  data() {
    return {
      left: false,
      right: false,
      mousePos: [0, 0],
      counter: 0,
      images: [
        { number: 1, image: require('../assets/img/gallery/6WrEAQvQ.jpg')},
        { number: 2, image: require('../assets/img/gallery/db_sbfl-113.jpg')},
        { number: 3, image: require('../assets/img/gallery/db_sbfl-26.jpg')},
        { number: 4, image: require('../assets/img/gallery/db_sbfl-37.jpg')},
        { number: 5, image: require('../assets/img/gallery/db_sbfl-48.jpg')},
        { number: 6, image: require('../assets/img/gallery/db_sbfl-49.jpg')},
        { number: 7, image: require('../assets/img/gallery/db_sbfl-54.jpg')},
        { number: 8, image: require('../assets/img/gallery/db_sbfl-55.jpg')},
        { number: 9, image: require('../assets/img/gallery/db_sbfl-6.jpg')},
        { number: 10, image: require('../assets/img/gallery/db_sbfl-65.jpg')},
        { number: 11, image: require('../assets/img/gallery/db_sbfl-68.jpg')},
        { number: 12, image: require('../assets/img/gallery/db_sbfl-72.jpg')},
        { number: 13, image: require('../assets/img/gallery/db_sbfl-79.jpg')},
        { number: 14, image: require('../assets/img/gallery/db_sbfl-80.jpg')},
        { number: 15, image: require('../assets/img/gallery/db_sbfl-88.jpg')},
        { number: 16, image: require('../assets/img/gallery/db_sbfl-93.jpg')},
        { number: 17, image: require('../assets/img/gallery/db_sbfl-99.jpg')},
        { number: 18, image: require('../assets/img/gallery/ElWOyirg.jpg')},
        { number: 19, image: require('../assets/img/gallery/OcMci5PA.jpg')},
        { number: 20, image: require('../assets/img/gallery/z0CoBA1w.jpg')}
      ],
      settings: {
        dots: false,
        arrows: true,
        infinite: true,
        centerMode: false,
        centerPadding: "0px",
        slidesToScroll: 1,
        variableWidth: true,
        draggable: false,
        autoplay: true,
        autoplaySpeed: 12.5,
        speed: 8000,
        cssEase: "linear",
        swipeToSlide: false
      }
    }
  },
  methods: {
    ...mapActions('shopify', []),
    ...mapMutations('shopify', ['setSelectedProductType', 'setSelectedProductID']),
    move(right) {
      if (right) {
        const toMove = this.images.shift()
        this.images.push(toMove)
        this.counter++
      } else {
        const toMove = this.images.pop()
        this.images.unshift(toMove)
        this.counter--
      }
    },
    startFollow(direction) {
      if (direction == 'LEFT') {
        if (this.left == true) {
          return
        }
        this.left = true
      }
      if (direction == 'RIGHT') {
        if (this.right == true) {
          return
        }
        this.right = true
      }
    },
    stopFollow(direction) {
      if (direction == 'LEFT') {
        this.left = false
      }
      if (direction == 'RIGHT') {
        this.right = false
      }
    },
    followPosition() {
      document.onmousemove = this.handleMouseMove.bind(this);
    },
    handleMouseMove(event) {
      if (!this.right && !this.left) {
        return
      }
        var eventDoc, doc, body;

        event = event || window.event; // IE-ism

        // If pageX/Y aren't available and clientX/Y are,
        // calculate pageX/Y - logic taken from jQuery.
        // (This is to support old IE)
        if (event.pageX == null && event.clientX != null) {
            eventDoc = (event.target && event.target.ownerDocument) || document;
            doc = eventDoc.documentElement;
            body = eventDoc.body;

            event.pageX = event.clientX +
              (doc && doc.scrollLeft || body && body.scrollLeft || 0) -
              (doc && doc.clientLeft || body && body.clientLeft || 0);
            event.pageY = event.clientY +
              (doc && doc.scrollTop  || body && body.scrollTop  || 0) -
              (doc && doc.clientTop  || body && body.clientTop  || 0 );
        }

      this.mousePos = [event.pageX, event.pageY]
        // Use event.pageX / event.pageY here
    },
  },
  mounted() {
    this.followPosition()
  },
  computed: {
  },
  // components: { VueSlickCarousel },
  watch: {
  }
}
</script>


<style scoped>

#gallery {
  width: 100%;
  position: relative;
}
.carousel {
  height: 100%;
  margin: 5rem 0px;
  display: flex;
}
img {
  height: 20rem;
  min-width: 8rem;
  object-fit: cover;
}
.target {
  height: 20rem;
  width: 50vw;
  position: absolute;
  top: 5rem;
  z-index: 100;
  cursor: none;
}
.left-target {
  left: 0px;
}
.right-target {
  right: 0rem;
}

.pointer {
  position: fixed;
  height: 6rem;
  width: auto;
  mix-blend-mode: difference;
  pointer-events: none;
  top: 0px;
  left: 0px;
  z-index: 900;
  transform: rotate(0deg) translate(-50%, -50%);
}
.pointer.right {
  transform: rotate(180deg) translate(50%, 50%);
}

@keyframes scroll {
  0%   {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(-50%);
  }
}

/* .scroll {
  animation-name: scroll;
  animation-duration: 60s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  white-space: nowrap;
  display: block;
  position: relative;
  display: inline-block;

  animation-play-state: paused;
}
.scroll.scroll-left {
  animation-play-state: running;
}

.scroll.scroll-right {
  animation-play-state: running;
  animation-duration: -60s;
} */



@media only screen and (max-width: 700px) {
  img {
    width: 100vw;
    height: 80vh;
    object-fit: contain;
  }
  .target {
    height: 80vh;
  }
  .left-target {
    width: 30vw;
  }
  .right-target {
    width: 70vw;
  }
  .carousel .number {
    margin-top: .5rem;
    margin-left: .5rem;
  }
  .pointer {
    display: none;
  }
}


</style>
