import Client from 'shopify-buy/index.unoptimized.umd';

const state = {
  productsLoadint: false,
  products: [],
  productTypes: [],
  selectedProductType: '',
  selectedProductID: '',
  selectedProduct: {},
  collections: [],
  client: {},
  checkout: {},
  checkoutID: '',
  checkoutLength: 0,
  showCart: false
};

const actions = {
  async initStore(context) {
    const client = await Client.buildClient({
      domain: 'side-track-cafe.myshopify.com',
      storefrontAccessToken: 'd2016d87b2fdb263823f8cc8d8969e58'
    });
    context.commit('setClient', client);
    context.dispatch('getProductsAndCollections')
    context.dispatch('setUpCheckout')
  },
  async getProductsAndCollections(context) {
    const collectionsPromise = state.client.collection.fetchAllWithProducts()
    const productsPromise = state.client.product.fetchAll()
    
    const products = await productsPromise
    const collections = await collectionsPromise

    context.commit('setProducts', products)
    context.commit('setCollections', collections)
    
    const productTypes = getProductTypes()
    context.commit('setProductTypes', productTypes)
  },
  async setUpCheckout(context) {
    let checkout = {}
    if (localStorage.checkout) {
      try {        
        checkout = await fetchCheckout(localStorage.checkout);
      } catch (error) {        
        checkout = await createCheckout()
      }
      if (checkout == null) {
        checkout = await createCheckout()
      }
    } else {
      checkout = await createCheckout();
    }
    if (!!checkout.completedAt == true) {      
      checkout = await createCheckout();
    }
    context.commit('setCheckoutID', localStorage.checkout)
    context.commit('setCheckout', checkout)    
    state.checkoutLength = checkout.lineItems.length
  },
  async updateSelectedProduct(context) {
    let filteredProducts = []
    if (!!state.products == true) { 
      filteredProducts = state.products.filter(product => {
        return product.id == state.selectedProductID
      })
    }
    let selectedProduct = filteredProducts[0]
    context.commit('setSelectedProduct', selectedProduct)
    selectedProduct = await fetchProduct(context, state.selectedProductID)
    context.commit('setSelectedProduct', selectedProduct)
  },
  async addToCart(context, { variantID, quantity }) {
    const lineItemsToAdd = [
      {
        variantId: variantID,
        quantity: quantity
      }
    ];
    const checkout = await state.client.checkout.addLineItems(state.checkoutID, lineItemsToAdd)
    context.commit('setCheckout', checkout)
  },
  async removeFromCart(context, variantID) {
    const lineItemIdsToRemove = [
      variantID
    ];
    const checkout = await state.client.checkout.removeLineItems(state.checkoutID, lineItemIdsToRemove)
        
    context.commit('setCheckout', checkout)
  }
}

const mutations = {
  setClient(state, client) {
    state.client = {...client}
  },
  setCheckoutID(state, checkoutID) {
    state.checkoutID = checkoutID    
  },
  setCheckoutLength(state, checkoutLength) {
    state.checkoutLength = checkoutLength
  },
  setProducts(state, products) {
    for (let index = 0; index < products.length; index++) {
      const element = {...products[index]};
      const splitID = element.id.split('/')
      element.id = splitID[splitID.length - 1]
      console.log(element)
      products[index] = element
    }
    console.log(products)
    state.products = products
  },
  setProductTypes(state, productTypes) {
    state.productTypes = productTypes
  },
  setCollections(state, collections) {
    state.collections = collections
  },
  setCheckout(state, checkout) {
    state.checkout = {...checkout}
  },
  setShowCart(state, showCart) {
    state.showCart = showCart
  },
  setSelectedProductType(state, selectedProductType) {
    state.selectedProductType = selectedProductType
  },
  setSelectedProductID(state, selectedProductID) {
    state.selectedProductID = selectedProductID
  },  
  async setSelectedProduct(state, selectedProduct) {
    state.selectedProduct = selectedProduct
  }
}

const getters = {  
  products(state) {
    return state.products;
  },
  productsByType(state) {
    const filteredProducts = state.products.filter(product => {
      return product.productType == state.selectedProductType
    })
    return filteredProducts
  },
  getCheckoutLength(state) {
    if (!!state.checkout.lineItems == false) {
      return 0
    }
    return state.checkout.lineItems.length
  }
}

function getProductTypes() {
  const products = state.products
  const types = [...new Set(products.map(product => product.productType))]
  return types
}

async function createCheckout() {
  const checkout = await state.client.checkout.create();
  localStorage.setItem("checkout", checkout.id);
  return checkout
}

async function fetchCheckout (checkoutId) {
  const checkout = await state.client.checkout.fetch(checkoutId)
  return checkout
}

async function fetchProduct (context, productID) {

  if (!!state.client.product == false) {
    await context.dispatch('initStore')
  }

  const product = await state.client.product.fetch(productID)

  return product
}



export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true
};