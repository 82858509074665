<template>
  <div class="popup"
    v-on-clickaway="handleFocusOut"
    :style="{'top': top + 'px', 'right': right + 'px'}"
   v-if="isOpen">
    <div class="header">
      <div v-show="!!title == true" class="title">{{title}}</div>
      <div class="exit"
        @click="close()"
      ><u>EXIT X</u></div>
    </div>
    <div class="body">
      <!-- <div>{{title}}</div> -->
      <img :src="img" alt="">
      <pre v-html="markdown"></pre>
    </div>
  </div>
</template>

<script>

import Markdown from 'marked'
import { mixin as clickaway } from 'vue-clickaway';

export default {
  name: 'Popup',
  mixins: [ clickaway ],
  data() {
    return {
      isOpen: false,
      top: 25,
      right: 30,
      isClickaway: this.clickAway
    }
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    html: {
      type: String,
      default: ''
    },
    img: {
      type: String,
      default: ''
    },
    clickAway: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    open() {
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
      this.$emit('closed')
    },
    handleFocusOut() {
      if (this.isClickaway) {
        this.close()
      }
    },
    setPosition(x, y) {
      this.right = x,
      this.top = y
    }
  },
  computed: {
    markdown() {
      return Markdown(this.html)
    }
  },
  mounted() {
  }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .popup {
    /* font-family: 'Tinos', serif; */
    font-family: "Selva Pro";

    position: fixed;

    min-width: 200px;
    min-height: 20vh;

    max-width: 400px;
    max-height: 50vh;

    overflow: auto;

    background: white;
    border: 1px black solid;

    display: flex;
    flex-direction: column;

    z-index: 600;
  }
  .popup *{
    white-space: break-spaces;
    font-family: 'Tinos', serif;
  }
  .header {
    display: flex;
    flex-direction: row;
    border-bottom: 1px black solid;
    font-size: 1rem;
  }
  .header * {
    padding: .5rem 1rem;
  }
  .header .title {
    flex: 1;
    border-right: 1px black solid;
    font-family: 'NT Dapper';

  }
  .header .exit {
    cursor: pointer;
    text-align: right;
  }
  .header .exit u{
    font-family: 'NT Dapper' !important;
  }
  .body {
    overflow: auto;
    padding: .5rem 0px;
    flex: 1;
    padding: .5rem 1rem;
  }
  .body img{
    max-height: 16rem;
    margin-bottom: 1rem;
  }
  pre, pre >>> p {
    margin: 0px;
  }
  pre >>> a {
    text-decoration: underline;
  }
  pre >>> a:not(.default):link    { color: #20aee6; }
  pre >>> a:not(.default):visited { color: #9b44ff; }


@media only screen and (max-width: 700px) {

  .popup {
    position: fixed;
    bottom: 0rem;
    left: 0rem;

    z-index: 900;

    min-width: unset;
    min-height: 15rem;
    max-height: unset;
    width: 100vw;
  }
  .body {
    overflow: auto;
    max-height: 75vh;
    padding-bottom: 1.5rem;
  }
  .body img{
    width: 100%;
    height: auto;
    max-height: unset;
  }
}

</style>