<template>
  <div id="vibes" class="noselect">
    <audio id="vibes-audio" autoplay loop>
      <source src="../assets/audio/preparing-for-sam.mp3" type="audio/mpeg">
    </audio>
    <!-- <button @click="toggle">
      <div v-show="musicPlaying == false" class="play">Sound On</div>
      <div v-show="musicPlaying == true" class="pause">Sound Off</div>
    </button> -->
  </div>
</template>

<script>

const storedVibes = "vibing"
import {  mapMutations, mapState } from 'vuex';

export default {
  name: 'Vibes',
  data() {
    return {
      notPlayedBefore: true
    }
  },
  props: {
  },
  methods: {
    startAudio() {
      if (this.notPlayedBefore) {
        let isVibing = window.localStorage.getItem(storedVibes);
        if (isVibing == null || isVibing == 1) {
          this.play()
        }
        this.notPlayedBefore = false
      }
    },
    play() {
      let vibesAudio = document.getElementById('vibes-audio')
      vibesAudio.volume = .3
      vibesAudio.play()
      this.storeVibes(1)

      this.playing = true
    },
    pause() {
      let vibesAudio = document.getElementById('vibes-audio')
      vibesAudio.pause()
      this.storeVibes(0)

      this.playing = false
    },
    storeVibes(isPlaying) {
      window.localStorage.setItem(storedVibes, isPlaying)
      this.setMusicPlaying(isPlaying)
    },
    toggle() {
      if (this.musicPlaying) {
        this.pause()
      } else {
        this.play()
      }
    },
    ...mapMutations('windows', ['setMusicPlaying'])
  },
  computed: {
    ...mapState('windows', ['musicPlaying'])
  },
  mounted() {
      let vibesAudio = document.getElementById('vibes-audio')
      vibesAudio.volume = .3
  }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  #vibes {
    position: fixed;
    top: 2rem;
    right: 2rem;
  }
  #vibes button{

    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    background: white;
    border: 1px black solid;
  }
  #vibes button:focus{
    outline: none;
  }
  #vibes div {
    padding: .25rem;
    font-size: 1rem;
  }
</style>