import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueGtag from "vue-gtag";

Vue.use(VueAxios, axios)
Vue.prototype.$axios = axios

Vue.config.productionTip = false

Vue.use(VueGtag, {
  config: { id: "UA-169201799-1" }
}, router);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')