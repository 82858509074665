<template>
  <div id="app">
    <added-to-cart
      ref="addedToCart"
    ></added-to-cart>
    <div id="cart-button" class="noselect"
      @click="setShowCart(true)"
      v-show="numInCart > 0"
    >
      <div class="bag">BAG</div>
      <div class="">{{numInCart}}</div>
    </div>
    <router-view/>
    <cart/>
    <notification/>
  </div>
</template>

<script>
// @ is an alias to /src
import Cart from '@/components/Cart'
import Notification from '@/components/Notification'
import AddedToCart from '@/components/AddedToCart'
// import { mapActions, mapMutations, mapGetters, mapState } from 'vuex';
import { mapActions, mapState, mapMutations } from 'vuex';

export default {
  name: 'App',
  data() {
    return {
      loaded: false,
    }
  },
  components: {
    Cart, Notification, AddedToCart
  },
  methods: {
    ...mapActions('shopify', ['initStore']),
    ...mapMutations('shopify', ['setShowCart']),
  },
  mounted() {
    this.initStore()
  },
  computed: {
    ...mapState('shopify', ['checkout', 'checkoutLength']),
    numInCart() {
      if (!!this.checkout.lineItems == false) {
        return -1
      }
      let num = 0
      for (let index = 0; index < this.checkout.lineItems.length; index++) {
        const element = this.checkout.lineItems[index]
        num += element.quantity
      }
      return num
    }
  },
  watch: {
    numInCart(n, o) {
      if (o == -1) {
        return
      }
      if (n > o) {
        this.$refs.addedToCart.open()
      }
    }
  }
}
</script>


<style>

#cart-button {
  position: fixed;
  top: 1rem;
  right: 1rem;
  background: white;
  border: 1.5px black solid;
  z-index: 800;
  font-size: 2rem;
  display: flex;
  cursor: pointer;
}
#cart-button > *{
  padding: 1rem 1.5rem;
}
#cart-button .bag {
  border-right: 1.5px black solid;
}

body {
  margin: 0px
}@font-face {
    font-family: 'Selva Pro';
    src: url('./assets/fonts/SelvaPro-Regular.woff2') format('woff2'),
        url('./assets/fonts/SelvaPro-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Selva Pro';
    src: url('./assets/fonts/SelvaPro-Bold.woff2') format('woff2'),
        url('./assets/fonts/SelvaPro-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'NT Dapper';
    src: url('./assets/fonts/NTDapper-Regular.woff2') format('woff2'),
        url('./assets/fonts/NTDapper-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

#app {
  font-family: "NT Dapper";
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.for-phone-only {
  display: none;
}
h1, h2 {
  font-size: 3rem;
  color: black;
  margin: 0px;
  display: inline-block;
  font-weight: 500;
}
a {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
a:hover{
  outline: #44caff 1px solid;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f100;
  /* border: black 1px solid; */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #f1f1f100;
  border: black 1px solid;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: black;
}

@media only screen and (max-width: 700px) {
  h1, h2 {
    font-size: 1.5rem;
  }

  #cart-button {
    font-size: 1.5rem;
  }

  #cart-button > *{
    padding: .75rem 1.25rem;
  }
  .for-phone-only {
    display: inherit;
  }
  .for-phone-only.inline {
    display: inline;
  }
}

</style>
