<template>
  <div id="notification" v-show="showNotification">
    <div class="header">
      <div class="exit"
        @click="showNotification = false"
      ><u>EXIT X</u></div>
    </div>
    <div class="body">
      <!-- <div>{{title}}</div> -->
      <img :src="img" alt="">
      <pre v-html="body"></pre>
    </div>
  </div>
</template>

<script>

import Markdown from 'marked'

export default {
  name: 'notification',
  data() {
    return {
      showNotification: false,
      body: '',
      title: '',
      img: '',
      excludedURLs: [
        "/menu/direct"
      ]
    }
  },
  props: {
  },
  methods: {
    async setup() {
      await this.getNotification()

      const path = this.$route.path
      for (let index = 0; index < this.excludedURLs.length; index++) {
        const exPath = this.excludedURLs[index]
        if (path == exPath) {
          this.showNotification = false
        }
      }
    },
    async getNotification() {
      let files = await require.context('../../public/collections/notifications/', false, /\.json$/);
      let notifications = files.keys().map(key => {
        let res = files(key);
        return res;
      });
      if (notifications.length < 1) {
        this.showNotification = false
        return
      }
      else {
        this.showNotification = true
        const notification = notifications[0]
        this.title = notification.title
        this.body = Markdown(notification.notification)
        this.img = '' + notification.featuredImage
      }
    }
  },
  computed: {
  },
  mounted() {
    this.setup()
  }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  #notification {
    position: fixed;
    bottom: 1.5rem;
    left: 1.75rem;

    min-width: 400px;
    min-height: 300px;

    overflow: auto;

    background: white;
    border: 1px black solid;

    display: flex;
    flex-direction: column;

    z-index: 900;

    font-family: "Selva Pro";
  }
  #notification *{
    white-space: break-spaces;
  }
  .header {
    display: flex;
    flex-direction: row;
    border-bottom: 1px black solid;
    padding: .5rem 1rem;
    font-size: 1rem;
  }
  .header .title {
    flex: 1;
  }
  .header .exit {
    cursor: pointer;
    width: 100%;
    text-align: right;
    font-family: 'NT Dapper';
  }
  .body {
    overflow: auto;
    max-height: 48vh;
    padding: .5rem 0px;
    flex: 1;
    padding: .5rem 1rem;
  }
  .body img{
    max-height: 16rem;
  }
  pre, pre >>> p {
    margin: 0px;
    font-family: "Selva Pro";
  }
  pre >>> a {
    text-decoration: underline;
    font-family: "Selva Pro";
  }
  pre >>> a:not(.default):link    { color: #20aee6; }
  pre >>> a:not(.default):visited { color: #9b44ff; }


@media only screen and (max-width: 700px) {

  #notification {
    position: fixed;
    bottom: 0rem;
    left: 0rem;

    min-width: unset;
    min-height: unset;
    width: 100vw;
  }
}

</style>